<template>
    <label @click="input.click()">
        <slot name="button">
            <i class="iconfont iconadd-circle"></i>
        </slot>
    </label>
</template>

<script>

import Axios from '@/axios'
export default {
    props: {
        accept:{
            type:String,
            default:'image/*'
        },
        multiple:{
            type:Boolean,
            default:false
        },
        fields:{
            type: Object,
            default:function(){
                return {
                    fileName:'fileName',
                    fileAddress:'fileAddress'
                }
            }
        },
    },
    components: {},
    data() {
        return {};
    },
    computed: {},
    watch: {},
    mounted() {
        this.input = document.createElement('input');
        this.input.setAttribute("type", "file");
        if(this.multiple){
            this.input.setAttribute("multiple", "multiple");
        };
        if(this.accept){
            this.input.setAttribute("accept", this.accept);
        };
        this.input.addEventListener('change', e => {


            var files = e.target.files;

            var arr = [];

            for (let i = 0; i < files.length; i++) {
                let form = new FormData();
                form.append("files", files[i]);

                let obj = {
                    loading:true,
                }
                obj[`${this.fields.fileName}`] = '';
                obj[`${this.fields.fileAddress}`] = '';

                Axios({
                    url: '/api/fileUpload',
                    method: 'post',
                    data:form,
                    headers:{
                        'accept': '*/*'
                    },
                }).then(res => {
                    // setTimeout(()=>{
                        obj.loading = false;
                        obj[`${this.fields.fileName}`] = res.data[0].fileName;
                        obj[`${this.fields.fileAddress}`] = res.data[0].fileAddress;
                    // },5000)
                }).catch(err => {
                    console.log(err)
                    obj.loading = false;
                    this.input.value = '';
                });
                arr.push(obj);
            }
            this.$emit("upload",arr);
            this.input.value = '';
        });
    },
    methods: {}
};
</script>

<style lang="scss" scoped>

</style>
